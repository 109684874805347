export const regex = {
  phoneRegex: /^((7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
};

export const I18N_COOKIE = 'i18n_locale';

export const RELOAD_TRIGGER = 'reloadTrigger';

export const ALLTRUCKS_SUPPORT_EMAIL = 'Support@alltrucks.online';

export const ALLTRUCKS_SUPPORT_PHONE = '8(800)550-70-87';

export const isTmsCore = process.env.IS_CORE === 'true';

export const APP_NAME = process.env.APP_NAME || 'All Trucks Online';

// Profile
export const RUSSIA_ISO_CODE = 0;
export const PHYSICAL_ENTITY_TYPE = 2;
export const LEGAL_ENTITY_TYPE = 1;
export const TRANSPORTER_ROLE_SLUG = 'transporter';
export const CLIENT_ROLE_SLUG = 'client';
export const FORWARDER_ROLE_SLUG = 'forwarder';

// Orders
export const INCORRECT_DOCUMENTS = 9;

export const ORDER_STATUSES = {
  DRAFT_STATUS: 1,
  WAITING_FOR_TRANSPORTER_STATUS: 2,
  TAKEN_STATUS: 3,
  IN_PROCESS_STATUS: 4,
  MOVED_STATUS: 5,
  CLOSED_STATUS: 6,
  CANCELED_STATUS: 7,
  ON_APPROVAL_STATUS: 8,
  STATUS_NOT_DISTRIBUTED: 9,
  DELETED_STATUS: 10,
};

export const INSURANCE_STATUSES = {
  FOR_CONSIDERATION_STATUS: 1,
  INSURED_STATUS: 2,
  NOT_INSURED_STATUS: 3,
};

// Roles
export const TRANSPORTER_ROLES = {
  TRANSPORTERS_PLATFORM_ROLE: 0,
  TECHNICAL_COUNTERPARTIES_ROLE: 1,
};

// Correspondence
export const LETTER_STATUSES = {
  RECEIVED_STATUS: 1,
  PROCESSED_STATUS: 2,
  DELETED_STATUS: 3,
};
export const DEFAULT_DELIVERY_SERVICE_ID = 1;

// Documents
export const APPROVE_TYPE = 2;
export const CANCEL_TYPE = 3;

export const DOC_APPROVED = 3;
export const DOC_CANCELED = 2;
export const DOC_NEW = 1;

// Colors
export const YELLOW_COLOR = 'yellow';
export const RED_COLOR = 'red';
export const GREEN_COLOR = 'green';

// Role
export const TRANSPORTER_ID = 4;

// Order modules
export const ORDER_MODULE_TYPE_AUTO = 0;

// Type
export const TYPE_CONTRACTOR_ALL = -1;
