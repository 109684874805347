import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1914eaa6 = () => interopDefault(import('../pages/bi/index.vue' /* webpackChunkName: "pages/bi/index" */))
const _b9b1695c = () => interopDefault(import('../pages/bidding/index.vue' /* webpackChunkName: "pages/bidding/index" */))
const _c2916dee = () => interopDefault(import('../pages/bidding/index/create.vue' /* webpackChunkName: "pages/bidding/index/create" */))
const _061f8f52 = () => interopDefault(import('../pages/bidding/index/edit.vue' /* webpackChunkName: "pages/bidding/index/edit" */))
const _9cc2fafc = () => interopDefault(import('../pages/bidding/index/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/edit/_id" */))
const _afe3860a = () => interopDefault(import('../pages/bidding/index/info.vue' /* webpackChunkName: "pages/bidding/index/info" */))
const _41bcd5b4 = () => interopDefault(import('../pages/bidding/index/info/_id.vue' /* webpackChunkName: "pages/bidding/index/info/_id" */))
const _6ab38bb4 = () => interopDefault(import('../pages/bidding/index/price-requests/create.vue' /* webpackChunkName: "pages/bidding/index/price-requests/create" */))
const _a19a6698 = () => interopDefault(import('../pages/bidding/index/price-requests/edit.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit" */))
const _c4d4bf42 = () => interopDefault(import('../pages/bidding/index/price-requests/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit/_id" */))
const _5a50d158 = () => interopDefault(import('../pages/bidding/index/price-requests/info.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info" */))
const _69ce99fa = () => interopDefault(import('../pages/bidding/index/price-requests/info/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info/_id" */))
const _710142b2 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _717a45a9 = () => interopDefault(import('../pages/companies/index/create.vue' /* webpackChunkName: "pages/companies/index/create" */))
const _0f89dcf7 = () => interopDefault(import('../pages/companies/index/edit.vue' /* webpackChunkName: "pages/companies/index/edit" */))
const _40d651bc = () => interopDefault(import('../pages/companies/index/edit/_id.vue' /* webpackChunkName: "pages/companies/index/edit/_id" */))
const _9d562614 = () => interopDefault(import('../pages/companies/index/edit/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/edit/_customerId/_clientId" */))
const _8ab03cca = () => interopDefault(import('../pages/companies/index/info.vue' /* webpackChunkName: "pages/companies/index/info" */))
const _0d17e9c6 = () => interopDefault(import('../pages/companies/index/info/_id.vue' /* webpackChunkName: "pages/companies/index/info/_id" */))
const _601e1e9a = () => interopDefault(import('../pages/companies/index/info/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/info/_customerId/_clientId" */))
const _251ddc39 = () => interopDefault(import('../pages/correspondence/index.vue' /* webpackChunkName: "pages/correspondence/index" */))
const _fd23937c = () => interopDefault(import('../pages/correspondence/index/create.vue' /* webpackChunkName: "pages/correspondence/index/create" */))
const _ee79c060 = () => interopDefault(import('../pages/correspondence/index/edit.vue' /* webpackChunkName: "pages/correspondence/index/edit" */))
const _7b61f50a = () => interopDefault(import('../pages/correspondence/index/edit/_id.vue' /* webpackChunkName: "pages/correspondence/index/edit/_id" */))
const _34eff122 = () => interopDefault(import('../pages/drivers/index.vue' /* webpackChunkName: "pages/drivers/index" */))
const _f6610d8e = () => interopDefault(import('../pages/drivers/index/create.vue' /* webpackChunkName: "pages/drivers/index/create" */))
const _3ab6b487 = () => interopDefault(import('../pages/drivers/index/edit.vue' /* webpackChunkName: "pages/drivers/index/edit" */))
const _841a86b4 = () => interopDefault(import('../pages/drivers/index/edit/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/edit/_id/_companyId" */))
const _34568daa = () => interopDefault(import('../pages/drivers/index/info.vue' /* webpackChunkName: "pages/drivers/index/info" */))
const _7bfe5b82 = () => interopDefault(import('../pages/drivers/index/info/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/info/_id/_companyId" */))
const _152db184 = () => interopDefault(import('../pages/instructions/index.vue' /* webpackChunkName: "pages/instructions/index" */))
const _7e3754c0 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _d3abdcf2 = () => interopDefault(import('../pages/no-access/index.vue' /* webpackChunkName: "pages/no-access/index" */))
const _73651c78 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _73c05e57 = () => interopDefault(import('../pages/orders/index/create.vue' /* webpackChunkName: "pages/orders/index/create" */))
const _7c3a4925 = () => interopDefault(import('../pages/orders/index/edit.vue' /* webpackChunkName: "pages/orders/index/edit" */))
const _68b77c50 = () => interopDefault(import('../pages/orders/index/edit/_id.vue' /* webpackChunkName: "pages/orders/index/edit/_id" */))
const _27584dc9 = () => interopDefault(import('../pages/orders/index/info.vue' /* webpackChunkName: "pages/orders/index/info" */))
const _d38ae218 = () => interopDefault(import('../pages/orders/index/info/_id.vue' /* webpackChunkName: "pages/orders/index/info/_id" */))
const _1a93e680 = () => interopDefault(import('../pages/orders/index/info/_id/getTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/getTrip" */))
const _61706a90 = () => interopDefault(import('../pages/orders/index/info/_id/trip.vue' /* webpackChunkName: "pages/orders/index/info/_id/trip" */))
const _c9939a56 = () => interopDefault(import('../pages/orders/index/info/_id/viewTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/viewTrip" */))
const _bc637744 = () => interopDefault(import('../pages/password-recovery/index.vue' /* webpackChunkName: "pages/password-recovery/index" */))
const _618eb000 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _610a97c0 = () => interopDefault(import('../pages/public-orders/index.vue' /* webpackChunkName: "pages/public-orders/index" */))
const _607327a7 = () => interopDefault(import('../pages/references/index.vue' /* webpackChunkName: "pages/references/index" */))
const _004691fc = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _4fd06482 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _fddae300 = () => interopDefault(import('../pages/transport/index.vue' /* webpackChunkName: "pages/transport/index" */))
const _28be93ca = () => interopDefault(import('../pages/transport/index/create.vue' /* webpackChunkName: "pages/transport/index/create" */))
const _85d47c2e = () => interopDefault(import('../pages/transport/index/edit.vue' /* webpackChunkName: "pages/transport/index/edit" */))
const _69e0f314 = () => interopDefault(import('../pages/transport/index/edit/_id.vue' /* webpackChunkName: "pages/transport/index/edit/_id" */))
const _6833c68d = () => interopDefault(import('../pages/transport/index/info.vue' /* webpackChunkName: "pages/transport/index/info" */))
const _d137f490 = () => interopDefault(import('../pages/transport/index/info/_id.vue' /* webpackChunkName: "pages/transport/index/info/_id" */))
const _f2fd6908 = () => interopDefault(import('../pages/transporters/index.vue' /* webpackChunkName: "pages/transporters/index" */))
const _73acaa9b = () => interopDefault(import('../pages/transporters-groups/index.vue' /* webpackChunkName: "pages/transporters-groups/index" */))
const _23f5d7f6 = () => interopDefault(import('../pages/trips/index.vue' /* webpackChunkName: "pages/trips/index" */))
const _6973681f = () => interopDefault(import('../pages/trips/index/trip.vue' /* webpackChunkName: "pages/trips/index/trip" */))
const _7416e36c = () => interopDefault(import('../pages/trips/index/trip/_id.vue' /* webpackChunkName: "pages/trips/index/trip/_id" */))
const _dba89566 = () => interopDefault(import('../pages/trips/index/trip/_id/index.vue' /* webpackChunkName: "pages/trips/index/trip/_id/index" */))
const _4d63df42 = () => interopDefault(import('../pages/trips/index/trip/_id/edit.vue' /* webpackChunkName: "pages/trips/index/trip/_id/edit" */))
const _6090fa65 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _7f15789f = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _22b209c8 = () => interopDefault(import('../pages/users/index/create.vue' /* webpackChunkName: "pages/users/index/create" */))
const _998959ac = () => interopDefault(import('../pages/users/index/edit.vue' /* webpackChunkName: "pages/users/index/edit" */))
const _772c0856 = () => interopDefault(import('../pages/users/index/edit/_id.vue' /* webpackChunkName: "pages/users/index/edit/_id" */))
const _5e5957ce = () => interopDefault(import('../pages/users/index/info.vue' /* webpackChunkName: "pages/users/index/info" */))
const _1c25e30e = () => interopDefault(import('../pages/users/index/info/_id.vue' /* webpackChunkName: "pages/users/index/info/_id" */))
const _526e3720 = () => interopDefault(import('../pages/companies/branches.vue' /* webpackChunkName: "pages/companies/branches" */))
const _183cc95e = () => interopDefault(import('../pages/companies/forwarders.vue' /* webpackChunkName: "pages/companies/forwarders" */))
const _407e2840 = () => interopDefault(import('../pages/orders/trips.vue' /* webpackChunkName: "pages/orders/trips" */))
const _0e71a748 = () => interopDefault(import('../pages/reports/realizations/index.vue' /* webpackChunkName: "pages/reports/realizations/index" */))
const _1c673e88 = () => interopDefault(import('../pages/public-orders/_id.vue' /* webpackChunkName: "pages/public-orders/_id" */))
const _78d44634 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bi",
    component: _1914eaa6,
    name: "bi"
  }, {
    path: "/bidding",
    component: _b9b1695c,
    name: "bidding",
    children: [{
      path: "create",
      component: _c2916dee,
      name: "bidding-index-create"
    }, {
      path: "edit",
      component: _061f8f52,
      name: "bidding-index-edit",
      children: [{
        path: ":id?",
        component: _9cc2fafc,
        name: "bidding-index-edit-id"
      }]
    }, {
      path: "info",
      component: _afe3860a,
      name: "bidding-index-info",
      children: [{
        path: ":id?",
        component: _41bcd5b4,
        name: "bidding-index-info-id"
      }]
    }, {
      path: "price-requests/create",
      component: _6ab38bb4,
      name: "bidding-index-price-requests-create"
    }, {
      path: "price-requests/edit",
      component: _a19a6698,
      name: "bidding-index-price-requests-edit",
      children: [{
        path: ":id?",
        component: _c4d4bf42,
        name: "bidding-index-price-requests-edit-id"
      }]
    }, {
      path: "price-requests/info",
      component: _5a50d158,
      name: "bidding-index-price-requests-info",
      children: [{
        path: ":id?",
        component: _69ce99fa,
        name: "bidding-index-price-requests-info-id"
      }]
    }]
  }, {
    path: "/companies",
    component: _710142b2,
    name: "companies",
    children: [{
      path: "create",
      component: _717a45a9,
      name: "companies-index-create"
    }, {
      path: "edit",
      component: _0f89dcf7,
      name: "companies-index-edit",
      children: [{
        path: ":id?",
        component: _40d651bc,
        name: "companies-index-edit-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _9d562614,
        name: "companies-index-edit-customerId-clientId"
      }]
    }, {
      path: "info",
      component: _8ab03cca,
      name: "companies-index-info",
      children: [{
        path: ":id?",
        component: _0d17e9c6,
        name: "companies-index-info-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _601e1e9a,
        name: "companies-index-info-customerId-clientId"
      }]
    }]
  }, {
    path: "/correspondence",
    component: _251ddc39,
    name: "correspondence",
    children: [{
      path: "create",
      component: _fd23937c,
      name: "correspondence-index-create"
    }, {
      path: "edit",
      component: _ee79c060,
      name: "correspondence-index-edit",
      children: [{
        path: ":id?",
        component: _7b61f50a,
        name: "correspondence-index-edit-id"
      }]
    }]
  }, {
    path: "/drivers",
    component: _34eff122,
    name: "drivers",
    children: [{
      path: "create",
      component: _f6610d8e,
      name: "drivers-index-create"
    }, {
      path: "edit",
      component: _3ab6b487,
      name: "drivers-index-edit",
      children: [{
        path: ":id?/:companyId?",
        component: _841a86b4,
        name: "drivers-index-edit-id-companyId"
      }]
    }, {
      path: "info",
      component: _34568daa,
      name: "drivers-index-info",
      children: [{
        path: ":id?/:companyId?",
        component: _7bfe5b82,
        name: "drivers-index-info-id-companyId"
      }]
    }]
  }, {
    path: "/instructions",
    component: _152db184,
    name: "instructions"
  }, {
    path: "/login",
    component: _7e3754c0,
    name: "login"
  }, {
    path: "/no-access",
    component: _d3abdcf2,
    name: "no-access"
  }, {
    path: "/orders",
    component: _73651c78,
    name: "orders",
    children: [{
      path: "create",
      component: _73c05e57,
      name: "orders-index-create"
    }, {
      path: "edit",
      component: _7c3a4925,
      name: "orders-index-edit",
      children: [{
        path: ":id?",
        component: _68b77c50,
        name: "orders-index-edit-id"
      }]
    }, {
      path: "info",
      component: _27584dc9,
      name: "orders-index-info",
      children: [{
        path: ":id?",
        component: _d38ae218,
        name: "orders-index-info-id",
        children: [{
          path: "getTrip",
          component: _1a93e680,
          name: "orders-index-info-id-getTrip"
        }, {
          path: "trip",
          component: _61706a90,
          name: "orders-index-info-id-trip"
        }, {
          path: "viewTrip",
          component: _c9939a56,
          name: "orders-index-info-id-viewTrip"
        }]
      }]
    }]
  }, {
    path: "/password-recovery",
    component: _bc637744,
    name: "password-recovery"
  }, {
    path: "/profile",
    component: _618eb000,
    name: "profile"
  }, {
    path: "/public-orders",
    component: _610a97c0,
    name: "public-orders"
  }, {
    path: "/references",
    component: _607327a7,
    name: "references"
  }, {
    path: "/register",
    component: _004691fc,
    name: "register"
  }, {
    path: "/settings",
    component: _4fd06482,
    name: "settings"
  }, {
    path: "/transport",
    component: _fddae300,
    name: "transport",
    children: [{
      path: "create",
      component: _28be93ca,
      name: "transport-index-create"
    }, {
      path: "edit",
      component: _85d47c2e,
      name: "transport-index-edit",
      children: [{
        path: ":id?",
        component: _69e0f314,
        name: "transport-index-edit-id"
      }]
    }, {
      path: "info",
      component: _6833c68d,
      name: "transport-index-info",
      children: [{
        path: ":id?",
        component: _d137f490,
        name: "transport-index-info-id"
      }]
    }]
  }, {
    path: "/transporters",
    component: _f2fd6908,
    name: "transporters"
  }, {
    path: "/transporters-groups",
    component: _73acaa9b,
    name: "transporters-groups"
  }, {
    path: "/trips",
    component: _23f5d7f6,
    name: "trips",
    children: [{
      path: "trip",
      component: _6973681f,
      name: "trips-index-trip",
      children: [{
        path: ":id?",
        component: _7416e36c,
        children: [{
          path: "",
          component: _dba89566,
          name: "trips-index-trip-id"
        }, {
          path: "edit",
          component: _4d63df42,
          name: "trips-index-trip-id-edit"
        }]
      }]
    }]
  }, {
    path: "/unsubscribe",
    component: _6090fa65,
    name: "unsubscribe"
  }, {
    path: "/users",
    component: _7f15789f,
    name: "users",
    children: [{
      path: "create",
      component: _22b209c8,
      name: "users-index-create"
    }, {
      path: "edit",
      component: _998959ac,
      name: "users-index-edit",
      children: [{
        path: ":id?",
        component: _772c0856,
        name: "users-index-edit-id"
      }]
    }, {
      path: "info",
      component: _5e5957ce,
      name: "users-index-info",
      children: [{
        path: ":id?",
        component: _1c25e30e,
        name: "users-index-info-id"
      }]
    }]
  }, {
    path: "/companies/branches",
    component: _526e3720,
    name: "companies-branches"
  }, {
    path: "/companies/forwarders",
    component: _183cc95e,
    name: "companies-forwarders"
  }, {
    path: "/orders/trips",
    component: _407e2840,
    name: "orders-trips"
  }, {
    path: "/reports/realizations",
    component: _0e71a748,
    name: "reports-realizations"
  }, {
    path: "/public-orders/:id?",
    component: _1c673e88,
    name: "public-orders-id"
  }, {
    path: "/",
    component: _78d44634,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
